import CollapsibleText_CollapsibleTextComponent from '../components/CollapsibleText/viewer/skinComps/CollapsibleText/CollapsibleText.skin';
import CollapsibleText_CollapsibleTextController from '../components/CollapsibleText/viewer/CollapsibleText.controller';


const CollapsibleText_CollapsibleText = {
  component: CollapsibleText_CollapsibleTextComponent,
  controller: CollapsibleText_CollapsibleTextController
};


export const components = {
  ['CollapsibleText_CollapsibleText']: CollapsibleText_CollapsibleText
};

